import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormModal } from "../redux/slices/modalSlice";
import { Form, Formik } from "formik";
import { addFeeSchema } from "../utils/formValidationSchema";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { createFees, fetchFees } from "../redux/slices/feeMgtSlice";
import { setloadTost } from "../redux/slices/toastSlice";
import { fetchLookupExamCategory } from "../redux/slices/lookupSlice";

const AddFeeForm = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const examCategoryData = useSelector(
    (state) => state?.lookup?.lookupExamCategory
  );

  const feeTypeData = useSelector((state) => state?.feeMgt?.feeType?.data);

  useEffect(() => {
    dispatch(fetchLookupExamCategory());
  }, [dispatch]);

  const initialValues = {
    feeName: "",
    feeType: "",
    feeFrequency: "",
    memberCategory: "",
    amount: "",
    examCategory: "",
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    setError("");
    try {
      const formData = {
        feeName: values?.feeName,
        feeTypeId: values?.feeType,
        frequency: values?.feeFrequency,
        memberCategory: values?.memberCategory,
        examCategoryId: values?.examCategory ? values?.examCategory : null,
        amount: values?.amount,
      };

      const response = await dispatch(createFees(formData));
      if (response?.payload?.isSuccess === true) {
        setLoading(false);
        dispatch(fetchFees());
        dispatch(setFormModal({ status: false, data: null }));
        dispatch(
          setloadTost({
            title: "Fee created",
            description: `${values?.feeName} fee name has been created successfully`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          })
        );
      } else {
        setLoading(false);
        setError(
          response?.payload?.errors?.map((errors) => errors) ||
            response?.payload?.error?.description ||
            "Failed to create fee "
        );
      }
    } catch (error) {
      setError(
        error?.payload?.message ||
          "Failed to create fee, please check your network"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {error && (
        <Alert status="error" className="mt-3" mb={3}>
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={addFeeSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, values, handleBlur, handleChange }) => {
          if (values?.feeType === "Examination") {
            setStatus(true);
          } else {
            setStatus(false);
          }
          return (
            <Form>
              <FormControl
                mt={4}
                isInvalid={errors.feeName && touched.feeName}
                color="#121212"
              >
                <FormLabel htmlFor="feeName" fontSize={"16px"}>
                  Fee Name
                </FormLabel>
                <Input
                  placeholder="Enter Here"
                  fontSize={"small"}
                  bg={"#F3F3F3"}
                  name="feeName"
                  focusBorderColor="#ffffff"
                  borderRadius={"0"}
                  borderColor={"#FFFFFF"}
                  _placeholder={{ color: "#B4B1B1" }}
                  _hover={{ borderColor: "#FFFFFF" }}
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.feeName}
                />
                {touched.feeName && errors.feeName && (
                  <FormErrorMessage>{errors.feeName}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                mt={4}
                isInvalid={errors.feeType && touched.feeType}
                color="#121212"
              >
                <FormLabel htmlFor="feeType" fontSize={"16px"}>
                  Fee Type
                </FormLabel>
                <Select
                  name="feeType"
                  placeholder="Select Fee Type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  bg={"#F3F3F3"}
                  borderRadius={"0"}
                  focusBorderColor="#ffffff"
                  borderColor={"#FFFFFF"}
                  _hover={{ borderColor: "#FFFFFF" }}
                  _placeholder={{ color: "#B4B1B1" }}
                  value={values?.feeType}
                >
                  {feeTypeData &&
                    feeTypeData.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                </Select>
                {touched.feeType && errors.feeType && (
                  <FormErrorMessage>{errors.feeType}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                mt={4}
                isInvalid={errors.feeFrequency && touched.feeFrequency}
                color="#121212"
              >
                <FormLabel htmlFor="feeFrequency" fontSize={"16px"}>
                  Fee Frequency
                </FormLabel>
                <Select
                  name="feeFrequency"
                  placeholder="Select Fee Frequency"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  bg={"#F3F3F3"}
                  borderRadius={"0"}
                  focusBorderColor="#ffffff"
                  borderColor={"#FFFFFF"}
                  _hover={{ borderColor: "#FFFFFF" }}
                  _placeholder={{ color: "#B4B1B1" }}
                  value={values?.feeFrequency}
                >
                  <option value="Once">Once</option>
                  <option value="Biennial">Biennial</option>
                  <option value="Annual">Annual</option>
                </Select>
                {touched.feeFrequency && errors.feeFrequency && (
                  <FormErrorMessage>{errors.feeFrequency}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                mt={4}
                isInvalid={errors.memberCategory && touched.memberCategory}
                color="#121212"
              >
                <FormLabel htmlFor="memberCategory" fontSize={"16px"}>
                  Member Category
                </FormLabel>
                <Select
                  name="memberCategory"
                  placeholder="Select Member Category"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  bg={"#F3F3F3"}
                  borderRadius={"0"}
                  focusBorderColor="#ffffff"
                  borderColor={"#FFFFFF"}
                  _hover={{ borderColor: "#FFFFFF" }}
                  _placeholder={{ color: "#B4B1B1" }}
                  value={values?.memberCategory}
                >
                  {status ? (
                    <option value="All"> All</option>
                  ) : (
                    <>
                      <option value="Student">Student</option>
                      <option value="NYSC">NYSC</option>
                      <option value="Graduate">Graduate</option>
                      <option value="Associate">Associate</option>
                      <option value="Fellow">Fellow</option>
                      <option value="Retiree">Retiree</option>
                    </>
                  )}
                </Select>
                {touched.memberCategory && errors.memberCategory && (
                  <FormErrorMessage>{errors.memberCategory}</FormErrorMessage>
                )}
              </FormControl>
              {status && (
                <FormControl
                  mt={4}
                  isInvalid={errors.examCategory && touched.examCategory}
                  color="#121212"
                >
                  <FormLabel htmlFor="feeType" fontSize={"16px"}>
                    Exam Category
                  </FormLabel>
                  <Select
                    name="examCategory"
                    placeholder="Select Exam Category"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    bg={"#F3F3F3"}
                    borderRadius={"0"}
                    focusBorderColor="#ffffff"
                    borderColor={"#FFFFFF"}
                    _hover={{ borderColor: "#FFFFFF" }}
                    _placeholder={{ color: "#B4B1B1" }}
                    value={values?.examCategory}
                  >
                    {examCategoryData &&
                      examCategoryData.map((item, index) => (
                        <option key={index} value={item?.value}>
                          {item?.text}
                        </option>
                      ))}
                  </Select>
                  {touched.examCategory && errors.examCategory && (
                    <FormErrorMessage>{errors.examCategory}</FormErrorMessage>
                  )}
                </FormControl>
              )}
              <FormControl
                mt={4}
                isInvalid={errors.amount && touched.amount}
                color="#121212"
              >
                <FormLabel htmlFor="amount" fontSize={"16px"}>
                  Amount
                </FormLabel>
                <Input
                  placeholder="Enter amount"
                  fontSize={"small"}
                  bg={"#F3F3F3"}
                  name="amount"
                  focusBorderColor="#ffffff"
                  borderRadius={"0"}
                  borderColor={"#FFFFFF"}
                  _placeholder={{ color: "#B4B1B1" }}
                  _hover={{ borderColor: "#FFFFFF" }}
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.amount}
                />
                {touched.amount && errors.amount && (
                  <FormErrorMessage>{errors.amount}</FormErrorMessage>
                )}
              </FormControl>
              <Flex justifyContent={"center"} alignItems={"center"} gap={5}>
                <Button
                  mt={10}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  lineHeight={"16px"}
                  width={"118px"}
                  background={"#D11A2A"}
                  _hover={{ background: " " }}
                  color={"#FFFFFF"}
                  type="submit"
                  isLoading={loading}
                  loadingText="Saving ..."
                >
                  Add Fee
                </Button>
                <Button
                  mt={10}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  lineHeight={"16px"}
                  width={"118px"}
                  background={""}
                  _hover={{ background: " " }}
                  color={"#8F9BB3"}
                  onClick={() => {
                    dispatch(setFormModal({ status: false, data: null }));
                  }}
                  loadingText="Saving ..."
                >
                  Cancel
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddFeeForm;
